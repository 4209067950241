import React from 'react';

// Components
import { Container, Segment, Icon } from 'semantic-ui-react';
import { EmojiRenderer } from './EmojiRenderer';

// Styles
import styles from './Footer.module.less';

/*
  本项目基于 AGPL 3.0 许可协议开源。
  在您自行部署本项目时，请保留此处的作者信息。
*/
const Footer: React.FC = () => (
  <Segment vertical>
    <Container textAlign="center">
      <div className={styles.footerIcons}>
        <p>星熠HOF</p>
        <p>Per aspera ad astra 穿越逆境，抵达繁星</p>
        <p>
          <a href="https://beian.miit.gov.cn/">鲁ICP备2022029209号</a>
        </p>
      </div>
      {/*/!* 人员按字典序排列 *!/*/}
      {/*<div className={styles.footerText}></div>*/}
    </Container>
  </Segment>
);

export default Footer;
